import MainFrame, {SubMenuTemplate} from "templates/MainFrame";
import * as routes from "_routes";

export default function Template({children}:{children:React.ReactNode}) {

    const subMenu = [routes.MediaTop, routes.MediaCreate];

    return (
        <MainFrame subMenu={<SubMenuTemplate subMenu={subMenu} />}>
            {children}
        </MainFrame>
    )
}
export function API_URL_BASE(): string {
  return process.env.REACT_APP_API_BASEURL ?? "";
}

export function API_URL_AUTH(): string {
  return process.env.REACT_APP_API_LOGIN ?? "";
}

export function API_URL_REGISTER(): string {
  return process.env.REACT_APP_API_REGISTER ?? "";
}

export function API_URL_LOGOUT(): string {
  return process.env.REACT_APP_API_LOGOUT ?? "";
}

export function API_LOGIN_USER(): string {
  return process.env.REACT_APP_API_USER ?? "";
}

export function API_LOGIN_PASS(): string {
  return process.env.REACT_APP_API_PASSWORD ?? "";
}

export function APP_ENV(): string {
  return process.env.REACT_APP_ENV ?? "production";
}

import React, { useState, useEffect, useContext } from "react";
import Template from "./Template";
import { Console_log } from "functions/common";
import * as handle from "functions/handleUseStats";
import { Grid, Button, InputLabel, TextField } from "@mui/material";
import {
  getMedia,
  storeMedia,
  updateMedia,
  deleteMedia,
} from "functions/api/Media";
import { useParams, useNavigate } from "react-router-dom";
import { CommonDataContext } from "Context";

type urlProps = {
  uuid: string;
};

export default function Create() {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [caption, setCaption] = useState("");
  const [url, setUrl] = useState("");
  const [image, setImage] = useState<File | null>(null);
  const { fetchData } = useContext(CommonDataContext);

  const styles = {
    image: {
      maxHeight: "480px",
      maxWidth: "480px",
    },
  };

  const { uuid } = useParams<urlProps>();

  //登録ボタンを押下したときの処理
  const handleStore = () => {
    if (uuid !== undefined) {
      return;
    }
    // 新規登録
    storeMedia({ name: name, caption: caption, file: image })
      .then((res: any) => {
        fetchData();
        navigate("/media");
      })
      .catch((error: any) => {
        Console_log("post:media", error);
      });
  };

  // 更新ボタンを押下したときの処理
  const handleUpdate = () => {
    if (uuid === undefined) {
      return;
    }
    // 更新
    updateMedia(uuid, { name: name, caption: caption })
      .then((res: any) => {
        fetchData();
        navigate("/media");
      })
      .catch((error: any) => {
        Console_log("post:media", error);
      });
  };

  // 削除ボタンを押下したときの処理
  const handleDelete = () => {
    if (uuid === undefined) {
      return;
    }

    deleteMedia(uuid)
      .then((res: any) => {
        fetchData();
        navigate("/media");
      })
      .catch((error: any) => {
        Console_log("delete:Media", error);
      });
  };

  // 画像変更時の処理
  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log("handle");
    if (event.target.files && event.target.files.length > 0) {
      console.log("set");
      setImage(event.target.files[0]);

      console.log(event.target.files[0]);
    }
  };

  // ボタンがクリックされたときの処理
  const handleButtonClick = () => {
    document.getElementById("file-input")?.click();
  };

  // UUIDをキーとしてデータ取得処理
  useEffect(() => {
    if (uuid === undefined) {
      setName("");
      setCaption("");
      setUrl("");
    } else {
      getMedia(uuid)
        .then((res: any) => {
          Console_log("get:Media", res.data);
          setName(res.data.name);
          setCaption(res.data.caption);
          setUrl(res.data.url);
        })
        .catch((error: any) => {
          Console_log("get:Media", error);
        });
    }
  }, [uuid]);

  return (
    <Template>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={10}>
          <InputLabel id={`name`}>名称</InputLabel>
          <TextField
            key={`name`}
            fullWidth
            id={`name`}
            variant="outlined"
            value={name}
            onChange={(e) => handle.handleInputFieldChange(e, setName)}
          />
        </Grid>

        <Grid item xs={10}>
          <InputLabel id={`name`}>説明文</InputLabel>
          <TextField
            key={`caption`}
            fullWidth
            id={`caption`}
            variant="outlined"
            value={caption}
            onChange={(e) => handle.handleInputFieldChange(e, setCaption)}
          />
        </Grid>

        {uuid === undefined ? (
          <>
            <Grid item xs={7}>
              <InputLabel id={`name`}>画像</InputLabel>
              <Button variant="contained" onClick={handleButtonClick}>
                ファイルを選択
              </Button>
              <input
                hidden
                id="file-input"
                type="file"
                accept="*"
                onChange={handleImageChange}
              />
            </Grid>
            <Grid item xs={7}>
              {image && (
                <img
                  src={URL.createObjectURL(image)}
                  alt="thumbnail"
                  style={styles.image}
                />
              )}
            </Grid>
          </>
        ) : (
          <Grid item xs={7}>
            <InputLabel id={`name`}>画像</InputLabel>
            <img src={url} alt="thumbnail" style={styles.image} />
          </Grid>
        )}

        {uuid !== undefined ? (
          <>
            <Grid item xs={12}>
              <Button fullWidth variant="contained" onClick={handleUpdate}>
                更新
              </Button>
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item xs={12}>
              <Button fullWidth variant="contained" onClick={handleDelete}>
                削除
              </Button>
            </Grid>
          </>
        ) : (
          <Grid item xs={12}>
            <Button fullWidth variant="contained" onClick={handleStore}>
              登録
            </Button>
          </Grid>
        )}
      </Grid>
    </Template>
  );
}

import { useEffect, useState } from "react";
import Template from "./Template";

export default function Main() {
  console.log("content-typoe Main");

  return (
    <Template>
      <h2>コンテンツ種類管理ページ</h2>
    </Template>
  );
}

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Template from "./Template";
import { Console_log } from "functions/common";
import * as handle from "functions/handleUseStats";
import { tMedia } from "types/Media";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import { getMedias } from "functions/api/Media";
import PageNation from "components/Pagenation";

export default function Main() {
  const [medias, setMedias] = useState<tMedia[]>([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const styles = {
    imgList: {
      gridTemplateColumns: "repeat(auto-fit, max(200px))",
      gridTemplateRows: "repeat(auto-fit, max(200px)",
      gap: "16px",
    },
    img: {
      width: "100%",
      height: "100%",
    },
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    getMedias(currentPage, {})
      .then((res: any) => {
        Console_log("get:media", res.data);
        setMedias(res.data.data);
        setCurrentPage(res.data.current_page);
        setLastPage(res.data.last_page);
        setTotalPages(res.data.last_page);
      })
      .catch((error: any) => {
        Console_log("get:media", error);
      });
  }, [currentPage]);

  return (
    <Template>
      <h1>メディア一覧</h1>
      <ImageList style={styles.imgList}>
        {medias.map((media) => (
          <ImageListItem key={media.uuid}>
            <img
              srcSet={`${media.url}?w=248&fit=crop&auto=format&dpr=2 2x`}
              src={`${media.url}?w=248&fit=crop&auto=format`}
              alt={media.caption}
              style={styles.img}
              loading="lazy"
            />
            <ImageListItemBar
              title={media.name}
              subtitle={media.caption}
              actionIcon={
                <Link to={`/media/${media.uuid}`}>
                  <IconButton
                    sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                    aria-label={`info about ${media.name}`}
                  >
                    <InfoIcon />
                  </IconButton>
                </Link>
              }
            />
          </ImageListItem>
        ))}
      </ImageList>
      <PageNation
        totalPages={totalPages}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
      />
    </Template>
  );
}

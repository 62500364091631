import { tDomain } from "types/Domain";

export const initDomain: tDomain = {
  number: "",
  domain: "",
  /*
  createdAt: "",
  updatedAt: "",
  deletedAt: "",
  */
  organization_name: "",
  ceo_name: "",
  ceo_post_name: "",
  tell: "",
  fax: "",
  postal_code: "",
  address: "",
  address_other: "",
  email: "",
  google_map: "",
  google_map_link: "",
  google_tm_script: "",
  caption: "",
};

import React from "react";
import { Console_log } from "functions/common";
import ReactDOM from "react-dom/client";
import "index.css";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider } from "react-router-dom";
import { routeLogout, LoggedIn, NotLoggedIn } from "_routes";

import { CommonDataProvider } from "Context";
// トップDomを取得
const rootDom = document.getElementById("root");

let token = localStorage.getItem("token");

if (rootDom) {
  const root = ReactDOM.createRoot(rootDom);
  root.render(
    <React.StrictMode>
      {/* path URL */}
      {/* element 画面 */}
      {token !== null ? (
        <CommonDataProvider>
          <RouterProvider router={LoggedIn()} />
        </CommonDataProvider>
      ) : (
        <RouterProvider router={NotLoggedIn()} />
      )}
    </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { useState, useEffect, useContext } from "react";
import { Console_log } from "functions/common";
import Template from "./Template";
import { useParams, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {
  ContentTypeHead,
  ContentTypeItem,
  ItemData,
  initialItemData,
} from "./components/Item";
import {
  updateContentType,
  deleteContentType,
  getContentType,
} from "functions/api/Content";
import { CommonDataContext } from "Context";

type urlProps = {
  uuid: string;
};

export default function Create() {
  const [name, setName] = useState("");
  const [caption, setCaption] = useState("");
  const [items, setItems] = useState<ItemData[]>([initialItemData]);
  const { loading, fetchData } = useContext(CommonDataContext);

  const navigate = useNavigate();

  const addItem = () => {
    setItems([...items, { ...initialItemData }]);
  };

  const removeItem = (index: number) => {
    setItems(items.filter((_, i) => i !== index));
  };

  const { uuid } = useParams<urlProps>();

  /**
   * フォームの入力値が変更された時の処理
   * @param index
   * @param fieldName
   * @param value
   */
  const handleFormInputChange = (
    index: number,
    fieldName: string,
    value: string | number
  ) => {
    setItems((prevFormData) => {
      const updatedFormData = [...prevFormData];
      updatedFormData[index] = {
        ...updatedFormData[index],
        [fieldName]: value,
      };
      return updatedFormData;
    });
  };

  //更新ボタンを押下したときの処理
  const handleSubmit = () => {
    if (uuid === undefined) {
      return;
    }

    updateContentType(uuid, {
      name: name,
      caption: caption,
      data_type: items,
    })
      .then((res: any) => {
        //setWeekData(res.data);
        Console_log("post:ContentType", res.data);
        navigate(`/content-type`);
        fetchData();
      })
      .catch((error: any) => {
        Console_log("post:ContentType", error);
      });
  };

  //削除ボタンを押下したときの処理
  const handleDelete = () => {
    if (uuid === undefined) {
      return;
    }

    // 確認用メッセージを表示
    const confirmMessage = "Are you sure you want to delete this content?";
    if (!window.confirm(confirmMessage)) {
      return;
    }

    deleteContentType(uuid, {})
      .then((res: any) => {
        //setWeekData(res.data);
        Console_log("post:ContentType", res.data);
        if (res.data.count > 0) {
          alert("削除しました");
          navigate(`/content-type`);
          fetchData();
        }
      })
      .catch((error: any) => {
        Console_log("post:ContentType", error);
      });
  };

  // UUIDをキーとしてデータ取得処理
  useEffect(() => {
    if (uuid === undefined) {
      return;
    }

    getContentType(uuid)
      .then((res: any) => {
        Console_log("get:ContentType", res.data);

        setName(res.data.name);
        setCaption(res.data.caption);
        setItems(res.data.data_type);
      })
      .catch((error: any) => {
        Console_log("get:ContentType", error);
      });
  }, [uuid]);

  console.log("items", items);
  return (
    <Template>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={6}>
          <ContentTypeHead
            name={name}
            caption={caption}
            setName={setName}
            setCaption={setCaption}
            changeEvent={handleFormInputChange}
          />
        </Grid>
        <Grid item xs={12}>
          {items?.map((item, index) => (
            <ContentTypeItem
              key={`contentType-${uuid}-${index}`}
              index={index}
              value={item}
              changeEvent={handleFormInputChange}
              removeItem={removeItem}
            />
          ))}
        </Grid>
        <Grid item xs={12}>
          <Button fullWidth variant="contained" onClick={addItem}>
            追加
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button fullWidth variant="contained" onClick={handleSubmit}>
            更新
          </Button>
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}>
          <Button fullWidth variant="contained" onClick={handleDelete}>
            削除
          </Button>
        </Grid>
      </Grid>
    </Template>
  );
}

import { create } from "functions/axios";

// コンテンツ関係
export function getContents(uuidType: string, params: any = {}) {
  const ins = create();
  return ins.get(`/content/${uuidType}/index`, { params: params });
}
export function getContentList() {
  const ins = create();
  return ins.get(`/content/list`, {});
}
export function getContent(uuidType: string, uuid: string) {
  const ins = create();
  return ins.get(`/content/${uuidType}/${uuid}`, {});
}
export function getContentCreate(uuidType: string) {
  const ins = create();
  return ins.get(`/content/${uuidType}/create`, {});
}
export function storeContent(uuidType: string, params: any) {
  const ins = create();
  return ins.post(`/content/${uuidType}/store`, params);
}
export function updateContent(uuidType: string, uuid: string, params: any) {
  const ins = create();
  return ins.post(`/content/${uuidType}/${uuid}/update`, params);
}
export function deleteContent(uuidType: string, uuid: string, params: any) {
  const ins = create();
  return ins.delete(`/content/${uuidType}/${uuid}/destroy`, params);
}

// コンテンツタイプ関係
export function getContentTypes() {
  const ins = create();
  return ins.get(`/content-type/index`, {});
}
export function getContentTypeList() {
  const ins = create();
  return ins.get(`/content-type/list`, {});
}
export function getContentType(uuid: string) {
  const ins = create();
  return ins.get(`/content-type/${uuid}`, {});
}
export function storeContentType(params: any) {
  const ins = create();
  return ins.post("/content-type/store", params);
}
export function updateContentType(uuid: string, params: any) {
  const ins = create();
  return ins.post(`/content-type/update/${uuid}`, params);
}
export function deleteContentType(uuid: string, params: any) {
  const ins = create();
  return ins.delete(`/content-type/destroy/${uuid}`, params);
}

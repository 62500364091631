import { create } from "functions/axios";
import { tDomain } from "types/Domain";

// ユーザ
export function getUsers(page: number, params: any = {}) {
  const ins = create();
  return ins.get(`/mst/user/index?page=${page}`, {});
}
export function getUserList(params: any = {}) {
  const ins = create();
  return ins.get(`/mst/user/list`, { params: params });
}
export function getUser(id: string) {
  const ins = create();
  return ins.get(`/mst/user/${id}`, {});
}

// 組織
export function getOrganize(domain: string) {
  const ins = create();
  return ins.get(`/mst/domain/${domain}`);
}
export function updateOrganize(params: tDomain) {
  const ins = create();
  return ins.post(`/mst/domain/update/${params.domain}`, params);
}

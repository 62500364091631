import React, { createContext, useState } from "react";
import Axios from "axios";
import * as env from "functions/env";

export const API_URL = {
  base: env.API_URL_BASE(),
  login: env.API_URL_BASE() + "/login",
  logout: env.API_URL_BASE() + "/logout",
  checkToken: env.API_URL_BASE() + "/check-token",
  register: env.API_URL_BASE() + "/register",
  user: env.API_URL_BASE() + "/user",
  domainList: env.API_URL_BASE() + "/wdc/domain-list",

  weekSummary: env.API_URL_BASE() + "/wdc/event-data/week-summary",
  userCount: env.API_URL_BASE() + "/wdc/event-data/user-count",
  eventCountByDomain: env.API_URL_BASE() + "/wdc/event-data/event-count/domain",
  eventCountByPage: env.API_URL_BASE() + "/wdc/event-data/event-count/page",

  // 対象ドメイン取得用
  getDomians: env.API_URL_BASE() + "/web-domain/index",
};

export function login(auth: any, callbacker: any) {
  const ins = create();
  ins
    .post(API_URL.login, {
      auth: auth,
    })
    .then((res: any) => {
      callbacker(res);
    })
    .catch((error: any) => {
      console.log(error.config);
      /*alert( JSON.stringify(error.toJSON() ) ); */
      callbacker(error);
    });
}

export function logout(callbacker: any | null = null) {
  let result: any;

  const ins = create();
  return ins
    .get(API_URL.logout, {})
    .then((res: any) => {
      // 成功でlocalstrageを削除
      localStorage.clear();
      result = res;
    })
    .catch((res: any) => {
      result = res.response;
    })
    .finally(() => {
      if (callbacker) {
        callbacker(result);
      }
    });
}

export function create() {
  //getCookieXsrfToken();
  let instance = Axios.create({
    baseURL: API_URL.base,
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + localStorage.getItem("token"),
      /*
      "X-CSRF-TOKEN": csrfToken,
      Referer: document.location.href,
      */
    },
    //xsrfHeaderName: "X-CSRF-TOKEN",
    withCredentials: true,
    //withXSRFToken: true,
    timeout: 60000,
    maxRedirects: 5,
  });

  return instance;
}

export function setToken(token: string = "") {
  return Axios.create({
    baseURL: API_URL.base,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    xsrfHeaderName: "X-XSRF-TOKEN",
    xsrfCookieName: "XSRF-TOKEN",
    withCredentials: true,
  });
}

export async function post(
  instance: any,
  url: string,
  data: Array<string | string>
) {
  try {
    // URL
    const response = await instance.post(url, data);
    return response.data;
  } catch (error) {
    return error;
  }
}

export function makeUrl(url: string, param: string, value: string) {
  const replacedUrl = url.replace(`:${param}`, value);
  return replacedUrl;
}

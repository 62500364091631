import { useEffect, useState } from 'react';
import Template from "../Template";
import { useParams, useSearchParams} from 'react-router-dom';
import * as axios from "functions/axios";
import {Console_log} from "functions/common";
import { LineChart } from '@mui/x-charts/LineChart';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


type AbcProps = {
  domain: string
}

export default function DomainSuumary() {

  const urlParams = useParams<AbcProps>();

  console.log(urlParams.domain);
  
  let axiosIns = axios.create();

  // ページ別イベント件数を取得
  const [useEventToPage, setEventToPage] = useState([]);
  useEffect(() => {
    let mount = true; //マウントされているか
    if (mount) {
      axiosIns.post(axios.API_URL.eventCountByPage, {
        'email': localStorage.getItem('username')
        ,'domain': urlParams.domain
      }).then((res: any) => {
        setEventToPage(res.data);
        Console_log("ページ別イベント件数を取得:useEventToPage", res.data);
      }).catch((error: any) => {
        Console_log("ページ別イベント件数を取得:useEventToPage", error);
      });
    }
    return () => { mount = false } //クリーンアップでマウントフラグをfalseに
  }, [urlParams.domain]);

  /*
  // 折れ線グラフ
  let lineChartX: string[] = [];
  let lineChartDataUser:any = []; // 適切な初期値を設定
  let lineChartDataClick:any = []; // 適切な初期値を設定
  let lineChartDataLoad:any = []; // 適切な初期値を設定
  if(useWeekData){
    lineChartX = Object.keys(useWeekData);
    lineChartX.map((value: any, index: number, key: any) => {
      lineChartDataClick.push(useWeekData[value]['clickCount']);
      lineChartDataLoad.push(useWeekData[value]['viewCount']);
      lineChartDataUser.push(useWeekData[value]['userCount']);
    });
  }
  */
  
  return (
    <Template>
      <Typography
        component="h2"
        color={'secondary'}
      >
        {urlParams.domain}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper style={{ width: '100%' }}>
            <TableByPage
              rows={useEventToPage}
            />
          </Paper>
        </Grid>
      </Grid>
    </Template>
  );
}
  
function TableByPage({rows}:any){

  return(
    <TableContainer component={Paper} key={JSON.stringify(rows)}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>href</TableCell>
            <TableCell align="right">user count</TableCell>
            <TableCell align="right">click count</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row:any) => (
            <TableRow
              key={JSON.stringify(row)}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left">{row.href}</TableCell>
              <TableCell align="right">{row.loadCount}</TableCell>
              <TableCell align="right">{row.clickCount}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

function TableByDomain({rows}:any){

  return(
    <TableContainer component={Paper} key={JSON.stringify(rows)}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>label</TableCell>
            <TableCell align="right">count</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row:any) => (
            <TableRow
              key={JSON.stringify(row)}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left">{row.eventName}</TableCell>
              <TableCell align="right">{row.count}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

import Template from "./Template";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Console_log } from "functions/common";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import PageNation from "components/Pagenation";
import { getEndInputs } from "functions/api/EndInput";
import { tEndInput } from "types/EndInput";

export default function Main() {
  const navigate = useNavigate();
  const [inputData, setInputData] = useState<tEndInput[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const styles = {
    imgList: {
      gridTemplateColumns: "repeat(auto-fit, max(200px))",
      gridTemplateRows: "repeat(auto-fit, max(200px)",
      gap: "16px",
    },
    img: {
      width: "100%",
      height: "100%",
    },
  };

  const TableContainerCustom = styled(TableContainer)(({ theme }) => ({
    "& table": {
      width: "auto",
    },
    "& tr>*": {
      minWidth: "150px",
      maxWidth: "400px",
    },
    "& th.caption": {
      maxWidth: "350px",
    },
    "& td.data": {
      width: "200px",
      "& img": {
        maxWidth: "200px",
        maxHeight: "200px",
      },
    },
  }));

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    getEndInputs({ page: currentPage })
      .then((res: any) => {
        Console_log("get:media", res.data);
        setInputData(res.data.data);
        setCurrentPage(res.data.current_page);
        setLastPage(res.data.last_page);
        setTotalPages(res.data.last_page);
      })
      .catch((error: any) => {
        Console_log("get:media", error);
      });
  }, [currentPage]);

  return (
    <Template>
      <TableContainerCustom>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>日時</TableCell>
              <TableCell>型</TableCell>
              <TableCell>タイトル、件名</TableCell>
              <TableCell>登録元URL</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {inputData.map((data: tEndInput, index: number) => {
              /*
              let title = "";
              if (data.input.title !== undefined) {
                title = data.input.title;
              } else if (data.input.subject !== undefined) {
                title = data.input.subject;
              }
                */

              return (
                <TableRow
                  key={data.uuid}
                  onClick={() => navigate(`/end-input/${data.uuid}`)}
                >
                  <TableCell>{data.created_at}</TableCell>
                  <TableCell>{data.type}</TableCell>
                  <TableCell>{}</TableCell>
                  <TableCell>{data.referrer}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainerCustom>
      <PageNation
        totalPages={totalPages}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
      />
    </Template>
  );
}

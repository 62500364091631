import { create } from "functions/axios";

// コンテンツ関係
export function getMedia(uuid: string) {
  const ins = create();
  return ins.get(`/end-input/${uuid}`, {});
}
export function storeMedia(params: any) {
  const ins = create();
  return ins.post(`/end-input/store`, params);
}
export function updateMedia(uuid: string, params: any) {
  const ins = create();
  return ins.post(`/end-input/update/${uuid}`, params);
}
export function deleteMedia(uuid: string) {
  const ins = create();
  return ins.delete(`/end-input/destroy/${uuid}`);
}

// エンドユーザインプット関係
export function getEndInputs(params: any = {}) {
  const ins = create();
  return ins.get(`/end-input/index`, { params: params });
}
export function getEndInput(uuid: any = {}) {
  const ins = create();
  return ins.get(`/end-input/${uuid}`, {});
}

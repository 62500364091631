import { Console_log } from "functions/common";
import { tMedia } from "types/Media";
import { tContentTypeDataType } from "types/Content";
import { RequireTextField } from "components/TextField";
import { typeList } from "const/ContentType";
import {
  FormControl,
  InputLabel,
  TextField,
  Select,
  Grid,
  MenuItem,
  FormControlLabel,
  Switch,
} from "@mui/material";

interface textValueProps {
  type: tContentTypeDataType;
  value: string | number | Date;
  changeEvent: (fieldName: string, value: string | number | Date) => void;
}

interface boolValueProps {
  type: tContentTypeDataType;
  value: boolean;
  changeEvent: (fieldName: string, value: boolean) => void;
}

interface mediaValueProps {
  type: tContentTypeDataType;
  medias: tMedia[];
  value: tMedia;
  changeEvent: (fieldName: string, value: tMedia) => void;
}

export function TypeText({ type, value, changeEvent }: textValueProps) {
  return type.required ? (
    <RequireTextField
      fullWidth
      id={`${type.label}.label`}
      label={type.label}
      value={value}
      variant="outlined"
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        changeEvent(type.label, e.target.value)
      }
    />
  ) : (
    <TextField
      fullWidth
      id={`${type.label}.label`}
      label={type.label}
      value={value}
      variant="outlined"
      onChange={(e) => changeEvent(type.label, e.target.value)}
    />
  );
}

export function TypeTextarea({ type, value, changeEvent }: textValueProps) {
  return type.required ? (
    <RequireTextField
      fullWidth
      id={`${type.label}.label`}
      label={type.label}
      value={value}
      variant="outlined"
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        changeEvent(type.label, e.target.value)
      }
    />
  ) : (
    <TextField
      fullWidth
      multiline
      rows={4}
      id={`${type.label}.label`}
      label={type.label}
      value={value}
      variant="outlined"
      onChange={(e) => changeEvent(type.label, e.target.value)}
    />
  );
}

export function TypeNumber({ type, value, changeEvent }: textValueProps) {
  return (
    <TextField
      fullWidth
      label={type.label}
      value={value}
      type={"number"}
      variant="outlined"
      onChange={(e) => changeEvent(type.label, e.target.value)}
    />
  );
}

export function TypeDate({ type, value, changeEvent }: textValueProps) {
  return (
    <TextField
      fullWidth
      label={type.label}
      value={value}
      type={"Date"}
      variant="outlined"
      onChange={(e) => changeEvent(type.label, e.target.value)}
    />
  );
}

export function TypeBool({ type, value, changeEvent }: boolValueProps) {
  return (
    <FormControlLabel
      label={type.label}
      control={
        <Switch
          checked={value}
          onChange={(e) => changeEvent(type.label, e.target.checked)}
        />
      }
    />
  );
}

export function TypeChoice({ type, value, changeEvent }: textValueProps) {
  return (
    <FormControl fullWidth>
      <InputLabel id={`${type.label}.label`}>{type.label}</InputLabel>
      <Select
        labelId={`${type.label}.label`}
        id={`${type.label}`}
        label={`${type.label}`}
        variant="outlined"
        value={value}
        onChange={(e) => changeEvent(type.label, e.target.value)}
      >
        {Object.entries(typeList).map(([key, value]) => (
          <MenuItem key={key} value={key}>
            {value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export function TypeMedia({
  type,
  medias,
  value,
  changeEvent,
}: mediaValueProps) {
  const styles = {
    img: {
      width: "100%",
      height: "100%",
      maxWidth: "300px",
      maxHeight: "300px",
    },
  };
  Console_log("TypeMedia", medias);
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <InputLabel id={`${type.label}.label`}>{type.label}</InputLabel>
          <Select
            labelId={`${type.label}.label`}
            id={`${type.label}`}
            label={`${type.label}`}
            variant="outlined"
            value={value?.uuid ? value.uuid : ""} // valueとして選択されたメディアのUUIDを使う
            onChange={(e) =>
              changeEvent(
                type.label,
                medias.find((media) => media.uuid === e.target.value)!
              )
            } // UUIDを使って対応するメディアを見つける
          >
            <MenuItem key={`content-nothing`} value={""}>
              （なし）
            </MenuItem>
            {medias.map((media: tMedia) => (
              <MenuItem key={media.uuid} value={media.uuid}>
                {media.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        {value && (
          <img style={styles.img} src={value.url} alt={value.caption} />
        )}
      </Grid>
    </Grid>
  );
}

export function TypeList(type: tContentTypeDataType) {
  return <TextField label={type.label} />;
}

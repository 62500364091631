import React, { useState, useContext } from "react";
import { Console_log } from "functions/common";
import Template from "./Template";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import * as axiosCustom from "functions/axios";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  ContentTypeHead,
  ContentTypeItem,
  ItemData,
  initialItemData,
} from "./components/Item";
import { storeContentType } from "functions/api/Content";
import { CommonDataContext } from "Context";

export default function Create() {
  const { fetchData } = useContext(CommonDataContext);
  const [name, setName] = useState("");
  const [caption, setCaption] = useState("");
  const [items, setItems] = useState<ItemData[]>([initialItemData]);

  const navigate = useNavigate();

  const addItem = () => {
    setItems([...items, { ...initialItemData }]);
  };

  const removeItem = (index: number) => {
    setItems(items.filter((_, i) => i !== index));
  };

  /**
   * フォームの入力値が変更された時の処理
   * @param index
   * @param fieldName
   * @param value
   */
  const handleFormInputChange = (
    index: number,
    fieldName: string,
    value: string | number
  ) => {
    setItems((prevFormData) => {
      const updatedFormData = [...prevFormData];
      updatedFormData[index] = {
        ...updatedFormData[index],
        [fieldName]: value,
      };
      return updatedFormData;
    });
  };

  //登録ボタンを押下したときの処理
  const handleSubmit = () => {
    Console_log("post:ContentType", items);
    storeContentType({
      name: name,
      caption: caption,
      data_type: items,
    })
      .then((res: any) => {
        //setWeekData(res.data);
        Console_log("post:ContentType", res.data);
        fetchData();
        alert("登録しました");
        navigate(`/content-type`);
      })
      .catch((error: any) => {
        Console_log("post:ContentType", error);
        alert("登録に失敗しました");
      });
  };

  return (
    <Template>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12}>
          <ContentTypeHead
            name={name}
            caption={caption}
            setName={setName}
            setCaption={setCaption}
            changeEvent={handleFormInputChange}
          />
        </Grid>

        <Grid item xs={12}>
          {items.map((item, index) => (
            <ContentTypeItem
              key={`contentType-create-${index}`}
              index={index}
              value={item}
              changeEvent={handleFormInputChange}
              removeItem={removeItem}
            />
          ))}
        </Grid>
        <Grid item xs={12}>
          <Button fullWidth variant="contained" onClick={addItem}>
            追加
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button fullWidth variant="contained" onClick={handleSubmit}>
            登録
          </Button>
        </Grid>
      </Grid>
    </Template>
  );
}

import { Pagination, Stack } from "@mui/material";
import { ChangeEvent } from "react";

interface MainProps {
  totalPages: number;
  currentPage: number;
  handlePageChange: (event: ChangeEvent<unknown>, page: number) => void;
}

export default function Main({
  totalPages,
  currentPage,
  handlePageChange,
}: MainProps) {
  return (
    <Stack spacing={2} alignItems="center" marginTop={2}>
      <Pagination
        count={totalPages}
        page={currentPage}
        onChange={handlePageChange}
        color="primary"
      />
    </Stack>
  );
}

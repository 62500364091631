import react, { useState, useEffect, useContext } from "react";
import { Console_log } from "functions/common";
import MainFrame, { SubMenuTemplate } from "templates/MainFrame";
import * as axiosCustom from "functions/axios";
import { CommonDataContext } from "Context";

export default function Template({ children }: { children: React.ReactNode }) {
  const [subMenu, setSubMenu] = useState<{ path: string; label: string }[]>([]);
  const { loading, contentTypes } = useContext(CommonDataContext);

  useEffect(() => {
    if (contentTypes && contentTypes.length > 0) {
      const contentTypeList = contentTypes.map((item: any) => ({
        path: `/content/${item.uuid}`,
        label: item.name,
      }));
      setSubMenu(contentTypeList);
    } else {
      setSubMenu([]);
    }
  }, [contentTypes]);

  if (loading || !contentTypes) {
    return <div>Loading...</div>;
  }

  return (
    <MainFrame subMenu={<SubMenuTemplate subMenu={subMenu} />}>
      {children}
    </MainFrame>
  );
}

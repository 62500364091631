import { APP_ENV } from "functions/env";
import { StringLiteral } from "typescript";
import { API_URL_BASE } from "functions/env";

export function Console_log(label: String, data: any): void {
  if (APP_ENV() != "production") {
    console.log(label);
    console.log(data);
  }
}

/**
 * クリップボードにコピー
 */
export const handleCopyToClipboard = (url: string) => {
  navigator.clipboard
    .writeText(`${API_URL_BASE()}${url}`)
    .then(() => {
      Console_log("Text copied to clipboard", `${url}`);
    })
    .catch((error) => {
      Console_log("Failed to copy text to clipboard:", error);
    });
};

import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';

export function RequireTextField({ label, ...rest }: { label: string; [key: string]: any }) {
    const theme = useTheme();
    return (
        <TextField
            label={label}
            InputProps={{
                style: {
                    borderBottom: `2px solid ${theme.palette.primary.main}`, // 下線のスタイルを定義
                },
            }}
            {...rest}
        />
    );
}

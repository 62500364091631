import { create } from "functions/axios";

// コンテンツ関係
export function getMedias(page: number, params: any = {}) {
  const ins = create();
  return ins.get(`/media/index?page=${page}`, {});
}
export function getMediaList(params: any = {}) {
  const ins = create();
  return ins.get(`/media/list`, { params: params });
}
export function getMedia(uuid: string) {
  const ins = create();
  return ins.get(`/media/${uuid}`, {});
}
export function storeMedia(params: any) {
  const ins = create();
  return ins.post(`/media/store`, params);
}
export function updateMedia(uuid: string, params: any) {
  const ins = create();
  return ins.post(`/media/update/${uuid}`, params);
}
export function deleteMedia(uuid: string) {
  const ins = create();
  return ins.delete(`/media/destroy/${uuid}`);
}

import { createTheme, ThemeProvider } from "@mui/material/styles";

export const color = {
  primary: "#32BEC8",
  secondary: "#FFFFFF",
  background: "#222222",
};

// 全体のテーマ設定
export const darkTheme = createTheme({
  palette: {
    primary: {
      main: color.primary,
      contrastText: color.secondary,
    },
    secondary: {
      main: color.secondary,
      contrastText: color.primary,
    },
    text: {
      primary: color.primary,
      secondary: color.secondary,
    },
    background: {
      default: color.background,
      paper: color.background,
    },
  },
  typography: {
    h1: {
      fontSize: "2.5rem",
      color: color.primary,
    },
    h6: {
      fontSize: "1.2rem",
      fontWeight: "bold",
      color: color.primary,
    },
    body1: {
      fontSize: "1rem",
      color: color.secondary,
    },
    body2: {
      fontSize: "0.8rem",
      color: color.secondary,
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          border: "2px solid #000000",
          boxShadow: "0px 0px 4px 2px #32BEC8 inset",
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: color.primary,
          "&.Mui-checked": {
            color: color.primary,
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputLabel-root": {
            color: color.primary,
          },
          "& .MuiInputLabel-root.Mui-focused": {
            color: color.primary,
          },
          "& .MuiInputBase-root": {
            color: color.primary,
          },
          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: color.primary,
          },
          "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: color.secondary,
          },
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              borderColor: color.primary,
            },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          color: color.primary,
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          border: "0.5px solid #000000",
          boxShadow: "0px 0px 4px 2px #32BEC8 inset",
          color: color.primary,
          borderRadius: "5px",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: color.primary,
          backgroundColor: color.background,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: color.primary,
          color: color.secondary,
          fontWeight: "bold",
          "&:hover": {
            backgroundColor: color.secondary,
            color: color.primary,
          },
        },
      },
    },
  },
});

export let useTheme = darkTheme;

export function ThemeProviderDark({ children }: any) {
  useTheme = darkTheme;
  return <ThemeProvider theme={useTheme}>{children}</ThemeProvider>;
}

export function ThemeProviderCustom({ children }: any) {
  return <ThemeProvider theme={useTheme}>{children}</ThemeProvider>;
}

import { tMedia } from "types/Media";
import { tContent } from "types/Content";

export function convertToContent(data: any): tContent {
  // ここでdataの構造に応じた処理を行い、ContentType型のオブジェクトを作成して返す
  return {
    uuid: data.uuid,
    domain: data.domain,
    contentType_uuid: data.contentType_uuid,
    caption: data.caption,
    items: convertToItem(data.items),
    created_at: new Date(data.created_at),
    updated_at: new Date(data.updated_at),
    released_at: new Date(data.released_at),
    deleted_at: data.deleted_at ? new Date(data.deleted_at) : null,
  };
}
export function convertToItem(
  data: any
): Record<string, string | number | boolean | Date | tMedia> {
  // ここでdataの構造に応じた処理を行い、Record<string, string | number | boolean | Date | media>型のオブジェクトを作成して返す

  let items: Record<string, string | number | boolean | Date | tMedia> = {};
  Object.keys(data).map((acc, key) => {
    items[acc] = data[acc];
  });

  return items;
}

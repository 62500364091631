import { tContentType, tContentTypeDataType } from "types/Content";

export const typeText = { label: "テキスト", value: 1 };
export const typeNumber = { label: "数値", value: 2 };
export const typeDate = { label: "日付", value: 3 };
export const typeBool = { label: "真偽", value: 4 };
export const typeChoice = { label: "選択肢", value: 5 };
export const typeMedia = { label: "メディア", value: 6 };
export const typeList = { label: "リスト", value: 7 };
export const typeTextarea = { label: "テキストエリア", value: 8 };

export const dataTypeList = () => {
  let result = [];

  result[typeText.value] = typeText.label;
  result[typeTextarea.value] = typeTextarea.label;
  result[typeNumber.value] = typeNumber.label;
  result[typeDate.value] = typeDate.label;
  result[typeBool.value] = typeBool.label;
  result[typeMedia.value] = typeMedia.label;

  return result;
};

export function convertToContentType(data: any): tContentType {
  // ここでdataの構造に応じた処理を行い、ContentType型のオブジェクトを作成して返す
  return {
    uuid: data.uuid,
    domain: data.domain,
    name: data.name,
    caption: data.caption,
    data_type: convertToDataType(data.data_type),
    created_at: new Date(data.created_at),
    updated_at: new Date(data.updated_at),
    deleted_at: data.deleted_at ? new Date(data.deleted_at) : null,
  };
}
export function convertToDataType(data: any): tContentTypeDataType[] {
  // ここでdataの構造に応じた処理を行い、ContentType型のオブジェクトを作成して返す

  return data.map((info: any) => {
    return {
      type: Number(info.type),
      label: info.label,
      default: info.default,
      required: Boolean(info.required),
    };
  });
}

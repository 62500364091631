import Template from "./Template";
import { useState, useEffect, useContext } from "react";
import { Console_log } from "functions/common";
import { useParams, useNavigate } from "react-router-dom";
import { convertToMedia } from "const/Media";
import { tMedia } from "types/Media";
import {
  TypeText,
  TypeNumber,
  TypeDate,
  TypeBool,
  TypeMedia,
  TypeTextarea,
} from "./components/Items";
import {
  typeText,
  typeNumber,
  typeDate,
  typeBool,
  typeMedia,
  convertToContentType,
  typeTextarea,
} from "const/ContentType";
import { tContent, tContentType, tContentTypeDataType } from "types/Content";
import { convertToContent } from "const/Content";
import { Button, TextField, Grid } from "@mui/material";
import {
  deleteContent,
  getContent,
  getContentCreate,
  storeContent,
  updateContent,
} from "functions/api/Content";
import { CommonDataContext } from "Context";
import { handleCopyToClipboard } from "functions/common";
import { convertInputDate, strDateTimeOrigin } from "functions/time";

type urlProps = {
  uuidType: string;
  uuid: string;
};
export default function Create() {
  const [type, setType] = useState<tContentType>();
  const [caption, setCaption] = useState("");
  const [released_at, setReleased_at] = useState<Date>(new Date());
  const [contentItem, setContentItem] = useState<{ [key: string]: any }>({});
  const { uuidType, uuid } = useParams<urlProps>();
  const { loading, medias, loginUser } = useContext(CommonDataContext);
  const [customMedias, setCustomMedias] = useState<tMedia[]>([]);
  const navigate = useNavigate();

  /**
   * フォームの入力値が変更された時の処理
   * @param fieldName
   * @param value
   */
  const handleFormInputChange = (
    fieldName: string,
    value: string | boolean | number | Date | tMedia
  ) => {
    setContentItem((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  // コンテンツ情報の取得
  useEffect(() => {
    console.log("uuidType", uuidType);
    console.log("uuid", uuid);
    if (uuidType !== undefined && uuid !== undefined) {
      // ContentTypeの取得
      // Contentの取得
      getContent(uuidType, uuid)
        .then((res: any) => {
          Console_log("get:Content.edit", res.data);

          // res.data.typeがContentTypeと一致しない場合の処理
          const convertedType: tContentType = convertToContentType(
            res.data.type
          ); // 適切な型への変換
          setType(convertedType);

          const convertedContent: tContent = convertToContent(res.data.content); // 適切な型への変換
          setCaption(convertedContent.caption);
          setContentItem(convertedContent.items);
          setReleased_at(convertedContent.released_at);
        })
        .catch((error: any) => {
          Console_log("error get:Content.edit", error);
          setType(undefined);
          setCaption("");
          setContentItem({});
          setReleased_at(new Date());
        });
    } else if (uuidType !== undefined && uuid === undefined) {
      getContentCreate(uuidType)
        .then((res: any) => {
          Console_log("get:ContentType.index", res.data.type);

          // res.data.typeがContentTypeと一致しない場合の処理
          const convertedType: tContentType = convertToContentType(
            res.data.type
          ); // 適切な型への変換
          setType(convertedType);
        })
        .catch((error: any) => {
          Console_log("post:ContentType.index", error);
          setType(undefined);
          setCaption("");
          setContentItem({});
          setReleased_at(new Date());
        });
    } else {
      setType(undefined);
      setCaption("");
      setContentItem({});
    }
  }, [uuidType, uuid]);

  // メディア情報の取得
  useEffect(() => {
    if (medias) {
      console.log("medias", medias);
      const convertedMedias: tMedia[] = medias.map((data: any, idx: number) => {
        return convertToMedia(data);
      });

      Console_log("convertedMedias", convertedMedias);

      setCustomMedias(convertedMedias);
    } else {
      setCustomMedias([]);
    }
  }, [medias]);

  // データタイプによって表示を変える
  const sortTypeDom = (dataType: tContentTypeDataType) => {
    switch (dataType.type) {
      case typeText.value:
        return (
          <TypeText
            type={dataType}
            value={contentItem[`${dataType.label}`]}
            changeEvent={handleFormInputChange}
          />
        );
      case typeTextarea.value:
        return (
          <TypeTextarea
            type={dataType}
            value={contentItem[`${dataType.label}`]}
            changeEvent={handleFormInputChange}
          />
        );
      case typeNumber.value:
        return (
          <TypeNumber
            type={dataType}
            value={contentItem[`${dataType.label}`]}
            changeEvent={handleFormInputChange}
          />
        );
      case typeDate.value:
        return (
          <TypeDate
            type={dataType}
            value={contentItem[`${dataType.label}`]}
            changeEvent={handleFormInputChange}
          />
        );
      case typeBool.value:
        return (
          <TypeBool
            type={dataType}
            value={contentItem[`${dataType.label}`]}
            changeEvent={handleFormInputChange}
          />
        );
      /*
        case typeChoice.value:
            return <TypeText type={dataType} value={contentItem[`${dataType.label}`]} changeEvent={handleFormInputChange} />;
        */
      case typeMedia.value:
        return (
          <TypeMedia
            type={dataType}
            medias={customMedias}
            value={contentItem[`${dataType.label}`]}
            changeEvent={handleFormInputChange}
          />
        );
      /*
        case typeList.value:
            return <TypeText type={dataType} value={contentItem[`${dataType.label}`]} changeEvent={handleFormInputChange} />;
        */
      default:
        return (
          <TypeText
            type={dataType}
            value={contentItem[`${dataType.label}`]}
            changeEvent={handleFormInputChange}
          />
        );
    }
  };

  //登録ボタンを押下したときの処理
  const handleStore = () => {
    if (uuidType === undefined) {
      return;
    }

    Console_log("post:Content", contentItem);

    const strFormatDatetime = strDateTimeOrigin(released_at);

    storeContent(uuidType, {
      items: contentItem,
      caption: caption,
      released_at: strFormatDatetime,
      deadline: null,
    })
      .then((res: any) => {
        //setWeekData(res.data);
        Console_log("post:content", res.data);

        navigate(`/content/${uuidType}`);
      })
      .catch((error: any) => {
        Console_log("post:content", error);
      });
  };

  const handleUpdate = () => {
    if (uuidType === undefined || uuid === undefined) {
      return;
    }

    const strFormatDatetime = strDateTimeOrigin(released_at);

    updateContent(uuidType, uuid, {
      items: contentItem,
      caption: caption,
      released_at: strFormatDatetime,
      deadline: null,
    })
      .then((res: any) => {
        //setWeekData(res.data);
        Console_log("post:content", res.data);

        navigate(`/content/${uuidType}`);
      })
      .catch((error: any) => {
        Console_log("post:content", error);
      });
  };

  const handleDelete = () => {
    if (uuidType === undefined || uuid === undefined) {
      return;
    }
    Console_log("delete:Content", contentItem);

    // 確認用メッセージを表示
    const confirmMessage = "Are you sure you want to delete this content?";
    if (!window.confirm(confirmMessage)) {
      return;
    }

    deleteContent(uuidType, uuid, {})
      .then((res: any) => {
        //setWeekData(res.data);
        Console_log("delete:content", res.data);
        if (res.data.count > 0) {
          alert("削除しました");
          navigate(`/content/${uuidType}`);
        }
      })
      .catch((error: any) => {
        Console_log("delete:content", error);
      });
  };

  if (loading || !type) {
    return <Template>loading...</Template>;
  }

  return (
    <Template>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <h1>{type.name}</h1>
        </Grid>
        <Grid item xs={2}>
          {uuid !== undefined && loginUser && (
            <Button
              onClick={() =>
                handleCopyToClipboard(
                  `/${loginUser.domain}/content/${uuidType}/${uuid}`
                )
              }
            >
              GET URL
            </Button>
          )}
        </Grid>
        <Grid item xs={12}>
          <p>{type.caption}</p>
        </Grid>
        <Grid item xs={10}>
          <TextField
            label={"公開日"}
            value={convertInputDate(released_at)}
            type={"datetime-local"}
            variant="outlined"
            onChange={(e) => setReleased_at(new Date(e.target.value))}
          />
        </Grid>
        <Grid item xs={10}>
          <TextField
            fullWidth
            label="説明文"
            value={caption}
            variant="outlined"
            onChange={(e) => setCaption(e.target.value)}
            multiline
            rows={4}
          />
        </Grid>
        {type.data_type.map((infoDataType: tContentTypeDataType) => {
          return (
            <Grid item xs={10}>
              {sortTypeDom(infoDataType)}
            </Grid>
          );
        })}

        <Grid item xs={12}></Grid>
        {uuid !== undefined ? (
          <>
            <Grid item xs={2}>
              <Button fullWidth variant="contained" onClick={handleUpdate}>
                更新
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button fullWidth variant="contained" onClick={handleDelete}>
                削除
              </Button>
            </Grid>
          </>
        ) : (
          <Grid item xs={2}>
            <Button fullWidth variant="contained" onClick={handleStore}>
              登録
            </Button>
          </Grid>
        )}
      </Grid>
    </Template>
  );
}

import react, { useState, useEffect } from "react";
import { Console_log } from "functions/common";
import MainFrame, { SubMenuTemplate } from "templates/MainFrame";
import * as routes from "_routes";
import * as axiosCustom from "functions/axios";

export default function Template({ children }: { children: React.ReactNode }) {
  const defMenu = {
    path: routes.routeLogout.path,
    label: routes.routeLogout.label,
  };

  const subMenu = [defMenu, routes.routeDomainInfo];

  return (
    <MainFrame subMenu={<SubMenuTemplate subMenu={subMenu} />}>
      {children}
    </MainFrame>
  );
}

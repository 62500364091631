import Template from "./Template";
import { useEffect, useState, useContext } from "react";
import { tMedia } from "types/Media";
import { Grid, Button, TextField } from "@mui/material";
import { CommonDataContext } from "Context";
import { log } from "console";
import { login } from "functions/axios";
import { getOrganize, updateOrganize } from "functions/api/mst";
import { tDomain } from "types/Domain";
import { initDomain } from "const/Mst";
import { handleCopyToClipboard } from "functions/common";
import InputAdornment from "@mui/material/InputAdornment";

export default function Main() {
  const { loading, loginUser } = useContext(CommonDataContext);

  const [organize, setOrganize] = useState<tDomain>(initDomain);

  /**
   * フォームの入力値が変更された時の処理
   * @param fieldName
   * @param value
   */
  const handleFormInputChange = (
    fieldName: string,
    value: string | boolean | number | Date | tMedia
  ) => {
    setOrganize((prevState: any) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  // コンテンツ情報の取得
  useEffect(() => {
    if (!loginUser) {
      return;
    }
    getOrganize(loginUser.domain).then((res) => {
      setOrganize(res.data);
    });
  }, [loginUser]);

  const handleUpdate = () => {
    console.log(organize);
    updateOrganize(organize)
      .then((res) => {
        if (res.status !== 200) {
          throw new Error("更新に失敗しました");
        }
        alert("更新しました");
      })
      .catch((error) => {
        alert("更新に失敗しました");
      });
  };

  return (
    <Template>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <h1>{organize.domain}</h1>
        </Grid>
        <Grid item xs={2}>
          <Button
            onClick={() =>
              handleCopyToClipboard(`/${organize.domain}/organize/show`)
            }
          >
            GET URL
          </Button>
        </Grid>
        <Grid item xs={10}>
          <TextField
            fullWidth
            label={"法人番号"}
            id="number"
            value={organize.number}
            variant="outlined"
            onChange={(e) => handleFormInputChange(e.target.id, e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">T</InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={10}>
          <TextField
            fullWidth
            label={"組織名"}
            id="organization_name"
            value={organize.organization_name}
            variant="outlined"
            onChange={(e) => handleFormInputChange(e.target.id, e.target.value)}
          />
        </Grid>
        <Grid item xs={10}>
          <TextField
            fullWidth
            label={"代表者名"}
            id="ceo_name"
            value={organize.ceo_name}
            variant="outlined"
            onChange={(e) => handleFormInputChange(e.target.id, e.target.value)}
          />
        </Grid>
        <Grid item xs={10}>
          <TextField
            fullWidth
            label={"代表役職"}
            id="ceo_post_name"
            value={organize.ceo_post_name}
            variant="outlined"
            onChange={(e) => handleFormInputChange(e.target.id, e.target.value)}
          />
        </Grid>
        <Grid item xs={10}>
          <TextField
            fullWidth
            label={"電話番号"}
            id="tell"
            value={organize.tell}
            variant="outlined"
            onChange={(e) => handleFormInputChange(e.target.id, e.target.value)}
          />
        </Grid>
        <Grid item xs={10}>
          <TextField
            fullWidth
            label={"FAX番号"}
            id="fax"
            value={organize.fax}
            variant="outlined"
            onChange={(e) => handleFormInputChange(e.target.id, e.target.value)}
          />
        </Grid>
        <Grid item xs={10}>
          <TextField
            fullWidth
            label={"郵便番号"}
            id="postal_code"
            value={organize.postal_code}
            variant="outlined"
            onChange={(e) => handleFormInputChange(e.target.id, e.target.value)}
          />
        </Grid>
        <Grid item xs={10}>
          <TextField
            fullWidth
            label={"住所"}
            id="address"
            value={organize.address}
            variant="outlined"
            onChange={(e) => handleFormInputChange(e.target.id, e.target.value)}
          />
        </Grid>
        <Grid item xs={10}>
          <TextField
            fullWidth
            label={"住所その他"}
            id="address_other"
            value={organize.address_other}
            variant="outlined"
            onChange={(e) => handleFormInputChange(e.target.id, e.target.value)}
          />
        </Grid>
        <Grid item xs={10}>
          <TextField
            fullWidth
            label={"メールアドレス"}
            id="email"
            value={organize.email}
            variant="outlined"
            onChange={(e) => handleFormInputChange(e.target.id, e.target.value)}
          />
        </Grid>

        <Grid item xs={10}>
          <TextField
            fullWidth
            multiline
            rows={5}
            label={"キャプション"}
            id="caption"
            value={organize.caption}
            variant="outlined"
            onChange={(e) => handleFormInputChange(e.target.id, e.target.value)}
          />
        </Grid>

        <Grid item xs={10}>
          <TextField
            fullWidth
            multiline
            rows={5}
            label={"Google Map iframe"}
            id="google_map"
            value={organize.google_map}
            variant="outlined"
            onChange={(e) => handleFormInputChange(e.target.id, e.target.value)}
          />
        </Grid>

        <Grid item xs={10}>
          <TextField
            fullWidth
            label={"Google Map URL"}
            id="google_map_link"
            value={organize.google_map_link}
            variant="outlined"
            onChange={(e) => handleFormInputChange(e.target.id, e.target.value)}
          />
        </Grid>

        <Grid item xs={10}>
          <TextField
            fullWidth
            multiline
            rows={5}
            label={"GTM スクリプト"}
            id="google_tm_script"
            value={organize.google_tm_script}
            variant="outlined"
            onChange={(e) => handleFormInputChange(e.target.id, e.target.value)}
          />
        </Grid>

        <Grid item xs={12}></Grid>
        <Grid item xs={2}>
          <Button fullWidth variant="contained" onClick={handleUpdate}>
            更新
          </Button>
        </Grid>
      </Grid>
    </Template>
  );
}

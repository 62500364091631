import React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import * as handle from "functions/handleUseStats";
import {
  Paper,
  FormControl,
  InputLabel,
  Button,
  TextField,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { dataTypeList } from "const/ContentType";
import { Console_log } from "functions/common";

export interface ItemData {
  type: string;
  label: string;
  require: number;
  default: any;
}

export const initialItemData: ItemData = {
  type: "",
  label: "",
  require: 0,
  default: "",
};

const typeList = dataTypeList();

const ItemPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  position: "relative",
}));

interface ContentTypeHeadProps {
  name: string;
  caption: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
  setCaption: React.Dispatch<React.SetStateAction<string>>;
  changeEvent: (
    index: number,
    fieldName: string,
    value: string | number
  ) => void;
}
export const ContentTypeHead: React.FC<ContentTypeHeadProps> = ({
  name,
  caption,
  setName,
  setCaption,
  changeEvent,
}) => {
  const Title = styled("h3")(({ theme }) => ({
    padding: theme.spacing(1),
  }));

  return (
    <>
      <Grid item xs={12} sm={6}>
        <InputLabel id={`label`}>コンテンツ名</InputLabel>
        <TextField
          fullWidth
          id={`label`}
          variant="outlined"
          value={name}
          onChange={(e) => handle.handleInputFieldChange(e, setName)}
        />
      </Grid>
      <Grid item xs={12} sm={10}>
        <InputLabel id={`caption`}>説明文</InputLabel>
        <TextField
          fullWidth
          id={`caption`}
          variant="outlined"
          value={caption}
          multiline
          rows={4}
          onChange={(e) => handle.handleInputFieldChange(e, setCaption)}
        />
      </Grid>
    </>
  );
};

interface ContentTypeItemProps {
  index: number;
  value: ItemData;
  changeEvent: (
    index: number,
    fieldName: string,
    value: string | number
  ) => void;
  removeItem: (index: number) => void;
}

export const ContentTypeItem: React.FC<ContentTypeItemProps> = ({
  index,
  value,
  changeEvent,
  removeItem,
}) => {
  console.log("ContentTypeItem", value);
  const Title = styled("h3")(({ theme }) => ({
    padding: theme.spacing(1),
  }));

  const getDataTypeKey = (dataTypeLabel: string) => {
    return Object.entries(dataTypeList).find(
      ([key, value]) => value === dataTypeLabel
    )?.[0];
  };

  const styles = {
    removeBtn: {
      position: "absolute",
      right: "10px",
      top: "10px",
    },
  };

  console.log("ContentTypeItem", value);

  return (
    <ItemPaper>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={2} md={2}>
          <Title>{`項目 ${index + 1}`}</Title>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth>
            <InputLabel id={`data_type.${index}.type.label`}>
              データ型
            </InputLabel>
            <Select
              labelId={`data_type.${index}.type.label`}
              id={`data_type.${index}.type`}
              label="データ型"
              variant="outlined"
              value={value.type}
              onChange={(e) => changeEvent(index, "type", e.target.value)}
            >
              {Object.entries(typeList).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4} md={4} justifyContent="center">
          <FormControlLabel
            control={
              <Checkbox
                id={`data_type.${index}.require`}
                checked={value.require === 1 ? true : false}
                onChange={(e) =>
                  changeEvent(index, "require", e.target.checked ? 1 : 0)
                }
              />
            }
            label="必須"
          />
        </Grid>

        <Grid item xs={12} sm={4} md={3}>
          <TextField
            fullWidth
            id={`data_type.${index}.label`}
            label="項目名"
            variant="outlined"
            value={value.label}
            onChange={(e) => changeEvent(index, "label", e.target.value)}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          {value.type === getDataTypeKey("テキスト") ? (
            <Text index={index} value={value} changeEvent={changeEvent} />
          ) : value.type === getDataTypeKey("テキストエリア") ? (
            <Number index={index} value={value} changeEvent={changeEvent} />
          ) : value.type === getDataTypeKey("数値") ? (
            <Number index={index} value={value} changeEvent={changeEvent} />
          ) : value.type === getDataTypeKey("日付") ? (
            <Data index={index} value={value} changeEvent={changeEvent} />
          ) : value.type === getDataTypeKey("メディア") ? (
            <Media index={index} value={value} changeEvent={changeEvent} />
          ) : (
            <Text index={index} value={value} changeEvent={changeEvent} />
          )}
        </Grid>
      </Grid>
      <Button
        sx={styles.removeBtn}
        variant="contained"
        onClick={() => removeItem(index)}
      >
        削除
      </Button>
    </ItemPaper>
  );
};

interface TextProps {
  index: number;
  value: ItemData;
  changeEvent: (
    index: number,
    fieldName: string,
    value: string | number
  ) => void;
}
function Text({ index, value, changeEvent }: TextProps) {
  return (
    <TextField
      fullWidth
      id={`data_type.${index}.default`}
      label="規定値"
      variant="outlined"
      value={value.default ?? ""}
      onChange={(e) => changeEvent(index, "default", e.target.value)}
    />
  );
}

function Number({ index, value, changeEvent }: TextProps) {
  return (
    <TextField
      fullWidth
      id={`data_type.${index}.default`}
      label="規定値"
      variant="outlined"
      value={value.default}
      type={"number"}
      onChange={(e) => changeEvent(index, "default", e.target.value)}
    />
  );
}

function Data({ index, value, changeEvent }: TextProps) {
  return (
    <TextField
      id={`data_type.${index}.default`}
      label="既定値"
      fullWidth
      variant="outlined"
      value={value.default}
      type={"Date"}
      placeholder=" "
      onChange={(e) => changeEvent(index, "default", e.target.value)}
    />
  );
}
/*
function Boolean({ index, value, changeEvent }: TextProps) {
  return (
    <FormControlLabel
      key={`data_type.${index}.default`}
      id={`data_type.${index}.default`}
      control={
        <Switch
          checked={value.default}
          onChange={(e) => changeEvent(index, "default", e.target.checked)}
        />
      }
      label="オン"
    />
  );
}
*/

function Media({ index, value, changeEvent }: TextProps) {
  return <></>;
}

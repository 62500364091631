import { tEndInput } from "types/EndInput";

export const isEndInput: tEndInput = {
  uuid: "",
  domain: "",
  created_at: "",
  updated_at: "",
  deleted_at: null,
  type: "",
  input: [],
  referrer: "",
};

import { tMedia } from "types/Media";

export function isMediaType(data: any): data is tMedia {
  console.log("isMediaType", data);
  return (
    data !== null &&
    typeof data === "object" && // オブジェクトであることを確認
    typeof data.uuid === "string" &&
    typeof data.name === "string" &&
    //(typeof !isset(data.caption) || data.caption === 'string' || data.caption === null) &&
    typeof data.file === "string" &&
    typeof data.url === "string"
  );
}

export function convertToMedia(data: any): tMedia {
  // ここでdataの構造に応じた処理を行い、ContentType型のオブジェクトを作成して返す
  return {
    uuid: data.uuid,
    domain: data.domain,
    name: data.name,
    caption: data.caption,
    file: data.file,
    url: data.url,
  };
}

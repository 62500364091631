import * as React from "react";
import { useEffect, useState, useContext } from "react";
import { useTheme, ThemeProviderCustom } from "Theme";
import * as routes from "_routes";
import { styled } from "@mui/material/styles";
import List from "@mui/material/List";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Box";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PeopleIcon from "@mui/icons-material/People";
import * as axiosCustom from "functions/axios";
import { Console_log } from "functions/common";
import { routeLogout } from "_routes";
import { Link as RouterLink } from "react-router-dom";
import { CommonDataContext } from "Context";
import TocIcon from "@mui/icons-material/Toc";
import MmsIcon from "@mui/icons-material/Mms";
import ArticleIcon from "@mui/icons-material/Article";
import InputIcon from "@mui/icons-material/Input";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import BusinessIcon from "@mui/icons-material/Business";

interface MainProps {
  subMenu: React.ReactNode | null;
  children: React.ReactNode;
}
function Main({ subMenu, children }: MainProps) {
  //const [currentPage, setCurrentPage] = useState('');
  const { loading, loginUser } = useContext(CommonDataContext);

  // 認証チェック
  const isAuthenticated = () => {
    // トークンが有効か☑️
    let axios = axiosCustom.create();
    axios
      .get(axiosCustom.API_URL.checkToken, { params: {} })
      .then((res: any) => {
        Console_log("get:checkToken", res.data);
        if (res.data !== true) {
          // エラーがある場合は、ログアウトしてログインページにリダイレクト
          window.location.href = routeLogout.path;
        }
      })
      .catch((error: any) => {
        Console_log("get:checkToken", error);
        // エラーがある場合は、ログアウトしてログインページにリダイレクト
        window.location.href = routeLogout.path;
      });
  };

  const styles = {
    container: {
      backgroundColor: useTheme.palette.background.default,
      color: useTheme.palette.primary.contrastText,
      height: "100vh",
      display: "flex",
      flexFlow: "row",
    },
    menu: {
      width: "200px",
      backgroundColor: useTheme.palette.primary.main,
      color: useTheme.palette.primary.contrastText,
    },
    content: {
      flexGrow: 1 /* 残りの横幅を均等に分配する */,
      flexBasis: 0,
      minWidth: 0 /* 必要に応じて、文字がはみ出ないようにするために設定 */,
      padding: useTheme.spacing(2),
      overflow: "scroll",
    },
  };

  const SubHeader = styled("div")(({ theme }) => ({
    paddingTop: theme.spacing(2),
    maxWidth: "200px",
    overflowY: "auto",
  }));

  useEffect(() => {
    isAuthenticated();
  }, []);

  return (
    <ThemeProviderCustom>
      <div style={styles.container}>
        <header style={styles.menu}>
          <List>
            {mainListItems}
            <ListItemButton component={RouterLink} to="/setting">
              <ListItemIcon>
                <BusinessIcon />
              </ListItemIcon>
              <CustomListItemText label={`${loginUser?.name}`} />
            </ListItemButton>
          </List>
        </header>
        {subMenu && <SubHeader style={styles.menu}>{subMenu}</SubHeader>}
        <Paper id="content-area" style={styles.content}>
          {children}
        </Paper>
      </div>
    </ThemeProviderCustom>
  );
}

function CustomListItemText({ label }: { label: string }) {
  return (
    <ListItemText
      primary={label}
      primaryTypographyProps={{ color: useTheme.palette.text.secondary }}
    />
  );
}

const mainListItems = (
  <React.Fragment>
    <ListItemButton component={RouterLink} to="/">
      <ListItemIcon>
        <img
          src={`${process.env.PUBLIC_URL}/logo.png`}
          alt={"メインロゴ"}
          width={24}
          style={{
            backgroundColor: "rgb(255, 255, 255)",
            borderRadius: "20px",
          }}
        />
      </ListItemIcon>
      <CustomListItemText label="Dashboard" />
    </ListItemButton>
    <ListItemButton component={RouterLink} to="/content-type">
      <ListItemIcon>
        <TocIcon />
      </ListItemIcon>
      <CustomListItemText label="型定義" />
    </ListItemButton>
    <ListItemButton component={RouterLink} to="/media">
      <ListItemIcon>
        <MmsIcon />
      </ListItemIcon>
      <CustomListItemText label="メディア" />
    </ListItemButton>
    <ListItemButton component={RouterLink} to="/content">
      <ListItemIcon>
        <ArticleIcon />
      </ListItemIcon>
      <CustomListItemText label="コンテンツ" />
    </ListItemButton>
    <ListItemButton component={RouterLink} to="/end-input">
      <ListItemIcon>
        <InputIcon />
      </ListItemIcon>
      <CustomListItemText label="フォーム" />
    </ListItemButton>
    <ListItemButton component={RouterLink} to="/wdc">
      <ListItemIcon>
        <ShowChartIcon />
      </ListItemIcon>
      <CustomListItemText label="WDC" />
    </ListItemButton>
  </React.Fragment>
);

export type tSubMenu = { path: string; label: string };

/**
 *
 * @returns
 */
export function SubMenuTemplate({
  subMenu,
}: {
  subMenu: tSubMenu[];
}): React.ReactElement {
  return (
    <>
      {subMenu.map((menu) => {
        return (
          <ListItemButton key={menu.path} component={RouterLink} to={menu.path}>
            <CustomListItemText label={menu.label} />
          </ListItemButton>
        );
      })}
    </>
  );
}

/**
 * サブメニューを表示するテンプレートコンポーネント
 * @param subMenus サブメニュー情報を含むオブジェクト
 * @returns マップされたサブメニューアイテムのリスト
 */
export function SubMenu02Template({
  subMenus,
}: {
  subMenus: { [key: string]: tSubMenu[] };
}): React.ReactElement {
  return (
    <>
      {Object.entries(subMenus).map(([key, menus]) => (
        <React.Fragment key={key}>
          <h3>{key}</h3>
          {menus.map((menu) => (
            <ListItemButton
              key={menu.label}
              component={RouterLink}
              to={menu.path}
            >
              <CustomListItemText label={menu.label} />
            </ListItemButton>
          ))}
        </React.Fragment>
      ))}
    </>
  );
}

export default Main;

import react, { useState, useEffect } from "react";
import { Console_log } from "functions/common";
import MainFrame, { SubMenu02Template } from "templates/MainFrame";
import * as routes from "_routes";
import * as axiosCustom from "functions/axios";

export default function Template({ children }: { children: React.ReactNode }) {
  const [subMenu, setSubMenu] = useState<{ [key: string]: { path: string; label: string; }[] }>({});

  useEffect(() => {
    let axiosIns = axiosCustom.create();
    axiosIns
      .get(axiosCustom.API_URL.getDomians, {})
      .then((res: any) => {
        Console_log("get:domains", res.data);

        const contentTypeList: { [key: string]: { path: string; label: string; }[] } = {};
        res.data.forEach(function(domains: any) {
          // code
          contentTypeList[domains.name] = [{
            path: `/wdc/${domains.key}`,
            label: 'TOP',
          },{
            path: `/wdc/${domains.key}/pages`,
            label: 'pages',
          },{
            path: `/wdc/${domains.key}/user`,
            label: 'users',
          }];

      });
        console.log(contentTypeList);
        // コンテンツタイプリストを展開してセットする
        //const mergedSubMenu = contentTypeList.reduce((acc, curr) => ({ ...acc, ...curr }), {});
        setSubMenu(contentTypeList);
      })
      .catch((error: any) => {
        Console_log("get:domains", error);
      });
  }, []);

  return (
    <MainFrame subMenu={<SubMenu02Template subMenus={subMenu} />}>
      {children}
    </MainFrame>
  );
}

import { useEffect, useState } from "react";
import * as axios from "functions/axios";
import Template from "pages/setting/Template";

export default function Mypage() {
  let axiosIns = axios.create();

  const [useData, setData] = useState({});

  // 初回レンダリング後と、useした値の更新後に自動で実行
  useEffect(() => {
    let mount = true; //マウントされているか

    console.log("Mypage useEffect");
    console.log(localStorage.getItem("token"));

    let data = { email: localStorage.getItem("username") };

    /*
    if (mount) {
      axiosIns.post(axios.API_URL.domainList, {'email': localStorage.getItem('username')}).then((res: any) => {
        console.log(res);
        setData(res.data)
      }).catch((error: any) => {
        console.log(error);
      });
    }
    */

    return () => {
      mount = false;
    }; //クリーンアップでマウントフラグをfalseに
  }, []);

  /*
  axiosIns.get(axios.API_URL.user, {
  }).then((res: any) => {
      console.log(res);
  });
  */

  return (
    <Template>
      <h2>Setting</h2>
    </Template>
  );
}

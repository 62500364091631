// src/context/CommonDataContext.js
import { createContext, useState, useEffect, ReactNode } from "react";
import { tContentType } from "types/Content";
import { tMedia } from "types/Media";
import { tUser } from "types/User";
import { getContentTypeList } from "functions/api/Content";
import { getMediaList } from "functions/api/Media";
import { getUserList } from "functions/api/mst";

interface CommonDataContextProps {
  loading: boolean;
  loginUser: tUser | null;
  contentTypes: tContentType[] | null;
  medias: tMedia[];
  fetchData: () => Promise<void>;
}

// デフォルト値を定義
const defaultValue: CommonDataContextProps = {
  loading: false,
  loginUser: null,
  contentTypes: null,
  medias: [],
  fetchData: async () => {},
};

export const CommonDataContext =
  createContext<CommonDataContextProps>(defaultValue);

interface CommonDataProviderProps {
  children: ReactNode;
}

export const CommonDataProvider = ({ children }: CommonDataProviderProps) => {
  const [loading, setLoading] = useState(true);
  const [loginUser, setLoginUser] = useState(null);
  const [contentTypes, setContentTypes] = useState<tContentType[]>([]);
  const [medias, setMedias] = useState<tMedia[]>([]);

  const fetchData = async () => {
    try {
      // 並行してデータを取得
      const [loginUserRes, contentTypesRes, mediasRed] = await Promise.all([
        getUserList({ email: localStorage.getItem("username") || "" }),
        getContentTypeList(),
        getMediaList(),
      ]);

      // データをセット
      setLoginUser(loginUserRes.data[0]);
      setContentTypes(contentTypesRes.data);
      setMedias(mediasRed.data);
    } catch (error) {
      console.error("Failed to fetch common data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <CommonDataContext.Provider
      value={{
        loading,
        loginUser,
        contentTypes,
        medias,
        fetchData,
      }}
    >
      {children}
    </CommonDataContext.Provider>
  );
};

import React, { useState, useEffect, ChangeEvent } from 'react';
import { SelectChangeEvent } from '@mui/material/Select';

/**
 * InputFieldの値が変更された時の処理
 * @param event 
 * @param set 
 */
export const handleInputFieldChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, set:React.Dispatch<React.SetStateAction<string>>) => {
    set(event.target.value);
};
  
  /**
   * SelectBoxの値が変更された時の処理
   * @param event 
   * @param set 
   */
export const handleSelectBoxChange = (event: SelectChangeEvent<string>, set:React.Dispatch<React.SetStateAction<string>>) => {
    set(event.target.value);
};
  
  /**
   * Checkboxの値が変更された時の処理
   * @param event 
   * @param set 
   */
 export const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>, set:React.Dispatch<React.SetStateAction<boolean>>) => {
    set(event.target.checked);
};
  
  